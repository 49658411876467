import React, { useState, useEffect } from "react";
import axios from "axios";
import PacmanLoader from "react-spinners/PacmanLoader";
import { useDispatch, useSelector } from "react-redux";
import currencyFormatter from "currency-formatter";
import myWooApi from "../myWooApi";
import { site_url_endpoint, access_token } from "../services/EndPointBaseUrl";

import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const api = new WooCommerceRestApi({
  url: "https://www.dev.thematureminds.com/gaurav/",
  consumerKey: "ck_a70afec6eb060f8394028e6626f35f440596a5f8",
  consumerSecret: "cs_c2f2153dda41075d9f8b452e46ace9aa787e166c",
  version: "wc/v3",
  wpAPI: true,
  queryStringAuth: true, // Force Basic Authentication as query string true and using under HTTPS
});

const Shop = () => {
  const dispatch = useDispatch();
  const [employees, setEmployee] = useState([]);
  const [loader, setLoader] = useState(false);

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#E9DB50");
  const override = `
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  border-color: red;
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fffbf2;
  z-index: 2;
  `;

  const { cartItems } = useSelector((state) => state);
  /*const axios_instance = axios.create({
    baseURL: site_url_endpoint,
    headers: { Authorization: "Bearer " + access_token },
  });*/

  useEffect(() => {
    loadEmployee();
  }, []);

  const loadEmployee = async () => {
    /*const result = axios_instance.get("/products");
    await result.then((result) => {
      setEmployee(result.data.reverse());
    });*/

    // List products
    await myWooApi
      .get("products", {
        per_page: 20, // 20 products per page
      })
      .then((response) => {
        setEmployee(response.data.reverse());
      });
    setLoader(true);
    setLoading(false);
  };

  // Start: Shoping Cart function
  const addToCart = (product) => {
    let alreadyExists = false;
    cartItems.forEach((x) => {
      if (x.product_id === product.id) {
        alreadyExists = true;
        x.quantity++;
      }
    });
    if (!alreadyExists) {
      cartItems.push({
        product_id: product.id,
        name: product.name,
        image: product.images[0]["src"],
        quantity: 1,
        price: product.price,
      });
    }
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    dispatch({ type: "ADD_TO_CART", payload: cartItems });
  };

  const removeItem = (product_id) => {
    const cartItemsx = cartItems.filter(
      (cartItem) => cartItem.product_id !== product_id
    );
    localStorage.setItem("cartItems", JSON.stringify(cartItemsx));
    dispatch({ type: "REMOVE_ITEM", payload: cartItemsx });
  };

  const getCartItemCount = () => {
    let count = 0;
    cartItems.forEach((cartItem) => {
      count = count + cartItem.quantity;
    });
    return count;
  };

  const getCartTotal = () => {
    let totalPrice = 0;
    cartItems.forEach((cartItem) => {
      totalPrice = totalPrice + cartItem.price * cartItem.quantity;
    });
    return totalPrice;
  };
  // End: Shoping Cart function

  return (
    <>
      {!loader ? (
        <PacmanLoader
          color={color}
          loading={loading}
          css={override}
          size={50}
        />
      ) : (
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              {employees.map((employee, index) => (
                <div className="col-sm-4" key={index}>
                  <div
                    className="card bg-secondary mb-3 mt-3"
                    style={{ maxWidth: "20rem" }}
                  >
                    <img
                      className="card-img-top"
                      src={employee.images[0]["src"]}
                    />
                    <div className="card-body">
                      <h4 className="card-title" style={{ fontSize: "12px" }}>
                        {employee.name}
                      </h4>
                      <p className="card-title">
                        {currencyFormatter.format(employee.price, {
                          code: "USD",
                        })}
                      </p>
                      <button
                        className="btn btn-primary"
                        onClick={() => addToCart(employee)}
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-sm-4">
            Cart Items {getCartItemCount()}
            <ul>
              {cartItems.map((cartItem, index) => (
                <li key={index}>
                  <img src={cartItem.image} style={{ width: "60px" }} />
                  <div style={{ fontSize: "12px" }}>{cartItem.name}</div>
                  <div>
                    {currencyFormatter.format(cartItem.price, { code: "USD" })}
                    {" x "}
                    {cartItem.quantity}
                  </div>
                  <div>
                    Subtotal:
                    {currencyFormatter.format(
                      cartItem.price * cartItem.quantity,
                      { code: "USD" }
                    )}
                  </div>
                  <a href="#" onClick={() => removeItem(cartItem.product_id)}>
                    Remove
                  </a>
                </li>
              ))}
            </ul>
            <div>
              Total Price:{" "}
              {currencyFormatter.format(getCartTotal(), { code: "USD" })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Shop;
