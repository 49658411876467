import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const intialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]"),
};

const contactReducer = (state = intialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return {
        cartItems: action.payload,
      };

    case "REMOVE_ITEM":
      return {
        cartItems: action.payload,
      };

    default:
      return state;
  }
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  contactReducer,
  composeEnhancer(applyMiddleware(thunk))
);

//store.dispatch(fetchPost());

export default store;
