import axios from "axios";

const ck = "ck_a70afec6eb060f8394028e6626f35f440596a5f8";
const cs = "cs_c2f2153dda41075d9f8b452e46ace9aa787e166c";
const baseURL = "https://www.dev.thematureminds.com/gaurav/";

const myWooApi = {
  get: (endpoint) => {
    return makeGetRequest("wp-json/wc/v3/" + endpoint);
  },
  post: (endpoint, bodyData) => {
    return makePostRequest("wp-json/wc/v3/" + endpoint, bodyData);
  },
};

function makePostRequest(endpoint, bodyData) {
  return axios.post(baseURL + endpoint, bodyData, {
    auth: {
      username: ck,
      password: cs,
    },
  });
}

function makeGetRequest(endpoint) {
  return axios.get(baseURL + endpoint, {
    auth: {
      username: ck,
      password: cs,
    },
  });
}

export default myWooApi;
