import logo from "./logo.svg";
import "./App.css";
import NavBar from "./share/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import { Provider } from "react-redux";
import store from "./store";
import Checkout from "./pages/Checkout";
function App() {
  return (
    <>
      <Router>
        <Provider store={store}>
          <NavBar />
          <div className="container">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/shop" component={Shop} />
              <Route exact path="/checkout" component={Checkout} />
            </Switch>
          </div>
        </Provider>
      </Router>
    </>
  );
}

export default App;
