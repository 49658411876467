import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import currencyFormatter from "currency-formatter";
import ClockLoader from "react-spinners/ClockLoader";
import axios from "axios";
import { site_url_endpoint, access_token } from "../services/EndPointBaseUrl";
import myWooApi from "../myWooApi";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const api = new WooCommerceRestApi({
  url: "https://www.dev.thematureminds.com/gaurav/",
  consumerKey: "ck_a70afec6eb060f8394028e6626f35f440596a5f8",
  consumerSecret: "cs_c2f2153dda41075d9f8b452e46ace9aa787e166c",
  version: "wc/v3",
  wpAPI: true,
  queryStringAuth: true, // Force Basic Authentication as query string true and using under HTTPS
});

// Custom interceptor to remove woocommerce custom User-Agent (not allowed in Chrome/Safari)
/*axios.interceptors.request.use(function (config) {
  const { headers = {} } = config || {};
  if (headers["User-Agent"]) delete config.headers["User-Agent"];

  return config;
});*/

const Checkout = () => {
  /*const axios_instance = axios.create({
    baseURL: site_url_endpoint,
    headers: { Authorization: "Bearer " + access_token },
  });*/
  const { cartItems } = useSelector((state) => state);

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#E9DB50");
  const override = `
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  border-color: red;
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fffbf2;
  z-index: 2;
  `;

  const getCartItemCount = () => {
    let count = 0;
    cartItems.forEach((cartItem) => {
      count = count + cartItem.quantity;
    });
    return count;
  };

  const getCartTotal = () => {
    let totalPrice = 0;
    cartItems.forEach((cartItem) => {
      totalPrice = totalPrice + cartItem.price * cartItem.quantity;
    });
    return totalPrice;
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const line_items = [];

    cartItems.forEach((cartItem) => {
      line_items.push({
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
      });
    });

    const {
      first_name,
      last_name,
      address_1,
      address_2,
      city,
      state,
      postcode,
      country,
      email,
      phone,
    } = data;

    const mainData = {
      payment_method: "cod",
      payment_method_title: "Cash on delivery",
      set_paid: true,
      status: "processing",
      billing: {
        first_name: first_name,
        last_name: last_name,
        address_1: address_1,
        address_2: address_2,
        city: city,
        state: state,
        postcode: postcode,
        country: country,
        email: email,
        phone: phone,
      },
      shipping: {
        first_name: first_name,
        last_name: last_name,
        address_1: address_1,
        address_2: address_2,
        city: city,
        state: state,
        postcode: postcode,
        country: country,
      },
      line_items: line_items,
      shipping_lines: [
        {
          method_id: "flat_rate",
          method_title: "Flat Rate",
          total: "15.00",
        },
      ],
    };

    console.log("--------1--------");
    console.log(mainData);
    console.log("--------1--------");

    /*const result = axios_instance.post("/orders", mainData);
    await result.then((result) => {
      console.log("--------2--------");
      console.log(result.data);
      console.log("--------2--------");
      setLoading(false);
    });*/

    await myWooApi.post("orders", mainData).then((response) => {
      console.log("--------2--------");
      console.log(response.data);
      console.log("--------2--------");
      setLoading(false);
    });
  };

  return (
    <>
      <ClockLoader color={color} loading={loading} css={override} size={100} />
      <div className="py-5 text-center">
        <h2>Checkout form</h2>
      </div>

      <div className="row">
        <div className="col-md-4 order-md-2 mb-4">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Your cart</span>
            <span className="badge badge-secondary badge-pill">
              {getCartItemCount()}
            </span>
          </h4>
          <ul className="list-group mb-3">
            {cartItems.map((cartItem, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between lh-condensed"
              >
                <img src={cartItem.image} style={{ width: "60px" }} />
                <div>
                  <h6 className="my-0" style={{ fontSize: "12px" }}>
                    {cartItem.name}
                  </h6>
                </div>
                <span className="text-muted">
                  {currencyFormatter.format(cartItem.price, { code: "USD" })}
                  {" x "}
                  {cartItem.quantity}
                </span>
              </li>
            ))}

            <li className="list-group-item d-flex justify-content-between">
              <span>Total (USD)</span>
              <strong>
                {currencyFormatter.format(getCartTotal(), { code: "USD" })}
              </strong>
            </li>
          </ul>

          <form className="card p-2">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Promo code"
              />
              <div className="input-group-append">
                <button type="submit" className="btn btn-secondary">
                  Redeem
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-8 order-md-1">
          <h4 className="mb-3">Billing address</h4>
          <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label>First name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("first_name", { required: true, maxLength: 20 })}
                />
                {errors?.first_name?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
                {errors?.firstName?.type === "maxLength" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    First name cannot exceed 20 characters
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label>Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  {...register("last_name", { required: true })}
                />
                {errors?.last_name?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label>Username</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">@</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  {...register("username", { required: true, maxLength: 20 })}
                />
                {errors?.username?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Email <span className="text-muted">(Optional)</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="you@example.com"
                {...register("email", { required: true })}
              />
              {errors?.email?.type === "required" && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  This field is required
                </div>
              )}
            </div>

            <div className="mb-3">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                id="address_1"
                placeholder="1234 Main St"
                {...register("address_1", { required: true })}
              />
              {errors?.address_1?.type === "required" && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  This field is required
                </div>
              )}
            </div>

            <div className="mb-3">
              <label>
                Address 2 <span className="text-muted">(Optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="address_2"
                placeholder="Apartment or suite"
                {...register("address_2")}
              />
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder=""
                  {...register("city", { required: true })}
                />
                {errors?.city?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label>phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder=""
                  {...register("phone", { required: true })}
                />
                {errors?.phone?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-5 mb-3">
                <label>Country</label>
                <select
                  className="custom-select d-block w-100"
                  id="country"
                  {...register("country", { required: true })}
                >
                  <option value="">Choose...</option>
                  <option>United States</option>
                </select>
                {errors?.country?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>
              <div className="col-md-4 mb-3">
                <label>State</label>
                <select
                  className="custom-select d-block w-100"
                  id="state"
                  {...register("state", { required: true })}
                >
                  <option value="">Choose...</option>
                  <option>California</option>
                </select>
                {errors?.state?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>
              <div className="col-md-3 mb-3">
                <label>Zip</label>
                <input
                  type="text"
                  className="form-control"
                  id="zip"
                  placeholder=""
                  {...register("postcode", { required: true })}
                />
                {errors?.postcode?.type === "required" && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    This field is required
                  </div>
                )}
              </div>
            </div>
            <hr className="mb-4" />
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="same-address"
              />
              <label className="custom-control-label">
                Shipping address is the same as my billing address
              </label>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="save-info"
              />
              <label className="custom-control-label">
                Save this information for next time
              </label>
            </div>
            <hr className="mb-4" />

            <button className="btn btn-primary btn-lg btn-block" type="submit">
              Continue to checkout
            </button>
          </form>
        </div>
      </div>

      <footer className="my-5 pt-5 text-muted text-center text-small">
        <p className="mb-1">© 2017-2018 Company Name</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="#">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Support</a>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default Checkout;
